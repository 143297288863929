<template>
  <div class="load-more relative" v-if="hasMore">
    <div class="load-more-content" tcenter>
      <a v-if="!loading" class="sec-button" @click="$emit('more')" skeleton>
        <span>{{ label || $locale["object"]["nav"]["view-more-btn"] }}</span>
      </a>
      <a v-else-if="loading"><iconic name="gloading" /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["hasMore", "label", "loading"],
};
</script>

<style lang="scss" scoped>
.load-more {
  color: $primary-color;
  .iconic {
    font-size: 180%;
  }
}
</style>
